import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DeliveryCodes } from 'src/app/data/model/deliver-codes';
import { SuspendResumePrintApiService } from 'src/app/shared/api/services/suspend-resume-print.service';

@Component({
  selector: 'app-suspend-print',
  templateUrl: './suspend-print.component.html',
  styleUrls: ['./suspend-print.component.scss']
})
export class SuspendPrintComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: DeliveryCodes, private router: Router,
    private suspendresume: SuspendResumePrintApiService) { }

    suspend():void{
      this.suspendresume.suspend_resume(true).subscribe();
      let currentUrl = this.router.url;
      this.router.navigateByUrl('/currentUrl',{skipLocationChange:true}).then(()=>{
        this.router.navigate([currentUrl]).then(()=>{
        });
      });
    }

}

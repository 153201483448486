import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENV_CONFIG, EnvironmentConfig } from 'src/app/core/enviroment-config';
import { SendApiService } from 'src/app/core/service/abstract/send-api.service';
import { ApiModule } from '../api.module';

@Injectable({
  providedIn: ApiModule,
})
export class SuspendResumePrintApiService extends SendApiService {
  constructor(http: HttpClient, @Inject(ENV_CONFIG) config: EnvironmentConfig) {
    super('reuse-center/suspend-print', http, config);
  }
}

import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Pageable } from 'src/app/core/service/abstract/read-api.service';

@Component({
    selector: 'app-custom-paginator',
    template: `
      <div class="custom-paginator">
        <button mat-button (click)="previousPage()" [disabled]="currentPage === 0"><mat-icon>keyboard_arrow_left</mat-icon></button>
        <button mat-button *ngFor="let page of pages" (click)="goToPage(page)" [class.active]="page === currentPage">{{ page + 1 }}</button>
        <button mat-button *ngIf="showNextEllipsis" (click)="nextSetOfPages()">...</button>
        <button mat-button (click)="nextPage()" [disabled]="currentPage === totalPages - 1"><mat-icon>keyboard_arrow_right</mat-icon></button>
        <mat-form-field>
          <mat-label>Items per page</mat-label>
          <mat-select [(value)]="itemsPerPage" (selectionChange)="onPageSizeChange($event.value)">
            <mat-option *ngFor="let size of pageSizeOptions" [value]="size">{{ size }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    `,
    styles: [`
      .custom-paginator {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
      }
      .custom-paginator .active {
        font-weight: bold;
        text-decoration: underline;
      }
      .mat-form-field {
        width: auto;
        margin-left: 10px;
      }
    `]
  })
  
export class CustomPaginatorComponent implements OnChanges {
  @Input() totalItems: number;
  @Input() itemsPerPage: number = 150;
  @Input() pageSizeOptions: number[] = [20, 50, 100, 150, 200, 500, 1000, 1500, 2000]
  @Output() pageChange = new EventEmitter<number>();
  @Output() pageSizeChange = new EventEmitter<number>();

  totalPages: number;
  currentPage: number = 0;
  pages: number[] = [];
  showNextEllipsis: boolean =false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['totalItems'] || changes['itemsPerPage']) {
      this.calculatePages();
    }
  }

  
  calculatePages(startPage: number = 0): void {
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
    const endPage = Math.min(this.totalPages, startPage + 9);
    if (startPage === 0) {
      this.pages = Array.from({ length: endPage }, (_, i) => startPage + i);
    } else {
      this.pages = Array.from({ length: endPage - startPage }, (_, i) => startPage + i);
    }
    this.showNextEllipsis = endPage < this.totalPages;
  }

  goToPage(page: number): void {
    if (page >= 0 && page < this.totalPages) {
      this.currentPage = page;
      this.pageChange.emit(this.currentPage);
      if (page === this.pages[this.pages.length - 1] && this.showNextEllipsis) {
        this.calculatePages(page + 1);
      } else if (page === this.pages[0] && this.currentPage > 0) {
        this.calculatePages(Math.max(page - 8, 0));
      }
    }
  }

  previousPage(): void {
    if (this.currentPage > 0) {
      this.goToPage(this.currentPage - 1);
    }
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages - 1) {
      this.goToPage(this.currentPage + 1);
    }
  }

  nextSetOfPages(): void {
    const nextStartPage = this.pages[this.pages.length - 1] + this.pages.length;
    this.calculatePages(nextStartPage);
    this.goToPage(nextStartPage);
  }

  onPageSizeChange(newSize: number): void {
    this.itemsPerPage = newSize;
    this.pageSizeChange.emit(newSize);
    this.calculatePages();
    this.goToPage(0); // Reset to first page whenever page size changes
  }
}
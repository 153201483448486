<div style="margin: 0 10px" *ngIf="filters  || client_side_filters ">
  <mat-chip-list>
    <mat-chip
      [removable]="true"
      (removed)="removeFilter.emit(filter.key)"
      style="margin: 5px 5px"
      *ngFor="let filter of filters | keyvalue"
    >
      <app-filter-chimp-text
        [key]="filter.key"
        [value]="filter.value"
        [field]="getField(filter.key)"
      ></app-filter-chimp-text>
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
  </mat-chip-list>
  <mat-chip-list>
    <mat-chip
      [removable]="true"
      (removed)="removeClientSideFilter.emit(client_side_filter.key)"
      style="margin: 5px 5px"
      *ngFor="let client_side_filter of client_side_filters | keyvalue"
    >
      <app-filter-chimp-text
        [key]="client_side_filter.key"
        [value]="client_side_filter.value"
        [field]="getField(client_side_filter.key)"
      ></app-filter-chimp-text>
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
  </mat-chip-list>
</div>

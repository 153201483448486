import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  templateUrl: './message-dialog.component.html',
  styles: [],
})
export class MessageDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: MessageParams) {}

  get message(): string {
    return this.data?.message;
  }
}

export type MessageParams = {
  message: string;
};

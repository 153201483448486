export type Permission = {
  action: Actions;
  model: string;
  module: string;
};

export enum Actions {
  ADD = 'add',
  VIEW = 'view',
  CHANGE = 'change',
  DELETE = 'delete',
}




export enum Modules {
  CDRI='reuse_center_app',
  SERVICE='mquadro_mobile_app',
  DATA_COLLECTOR = 'data_collector',
  AUTH = 'portal_auth',
  MANAGEMENT = 'group_management',
  TELEMETRY = 'telemetries',
  MOBILE = 'mquadro_mobile_app',
  MODA = 'm2_legacy',
  REPORT = 'report',
  REGISTRY = 'customer_registry',
  CONFERRALS = 'conferrals',
  REUSECENTER = 'reuse_center_app',
  TAG_APP_API ='tag_app_api',
  BIN_COLLECTION = 'bin_collection'
}

export enum Models {
  CUSTOMREFRESHTOKEN='customrefreshtoken',
  WHITELISTVEHICLE='vehiclewhitelist',
  APPMANAGEMENT='appmanagement',
  WHITELIST='whitelist',
  WLTAGLIST='wltaglist',
  CUSTOMREPORTS='customreports',
  CDRILIMITS='reusecenterlimits',
  KIOSK='kiosk',
  OPERATORSGROUP='operatorscard',
  OPERATORSCARD='operatorsgroup',
  ECOPOINTCATEGORY='ecopointcategories',
  ECOPOINTKIOSK='ecopointkiosk',
  ECOPOINTLIMIT='ecopointlimit',
  ECOPOINTOPERATOR='ecopointoperator',
  ECOPOINTGROUP='ecopointgroup',
  OPERATOR= 'operator',
  ECOPOINT = 'ecopoint',
  DELDOCUMENTS='reusecenterdocuments',
  DELCODE ='deliverycodes',
  DELIVERY='delivery',
  DRIVER='modadriverstbl',
  VEHICLEQRCODE='vehicleqrcode',
  DEVICE = 'device',
  DEVICE_COLLECTOR = 'devicecollector',
  PORTAL_GROUP = 'customergroup',
  USER_PORTAL_GROUP = 'userportalgroup',
  VEHICLE = 'vehicle',
  VEHICLESVIEW = 'vehiclesview',
  TELEMETRY = 'telemetry',
  PORTAL_USER = 'appuser',
  ORDINIS = 'serviceorder',
  GPSTRACK= 'trackinggps',
  GPSTRACKVIEW= 'gpstrackpointview',
  DEVICEEVENT = 'telemetriesdeviceeventview',
  CHASSIS= 'telemetrieschassisstate',
  GRAFANA = 'grafanaaccesdata',
  CONTRACT = 'customercontract',
  CUSTOMER = 'customerregistry',
  ESTATES = 'realestatedata',
  CONFERRAL = 'conferral',
  CARDUTILITY = 'modatesseretbl',
  ESTATECONTRACT = 'realestatecontractassignment',
  CORESIDENT = 'estatecoresident',
  TAGRES = 'taggableresource',
  CONTRES='contractresourceassignment',
  TAG_EVENT='tagevent'
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { InitialsUpperPipe } from './pipes/initials-upper.pipe';
import { FormApiValidationErrorsComponent } from './components/form-api-validation-errors/form-api-validation-errors.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { translateLoaderFactory } from '../i18n/i18n.module';
import { HttpClient } from '@angular/common/http';
import { GridFiltersComponent } from './dialogs/grid-filters/grid-filters.component';
import { CancelConfirmDialogComponent } from './dialogs/cancel-confirm-dialog/cancel-confirm-dialog.component';
import { FiltersChimpComponent } from './components/filters-chimp/filters-chimp.component';
import { GridMenuComponent } from './components/grid-menu/grid-menu.component';
import { ApiModule } from './api/api.module';
import { FilterChimpTextComponent } from './components/filter-chimp-text/filter-chimp-text.component';
import { SendOrdersComponent } from './dialogs/send-orders/send-orders.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { QRDialogComponent } from './dialogs/QRDialog/QRcode-dialog.component';
import { MessageDialogComponent } from './dialogs/message-dialog/message-dialog.component';
import { SuspendPrintComponent } from './dialogs/suspend-resume-print/components/suspend-print/suspend-print.component';
import { ResumePrintComponent } from './dialogs/suspend-resume-print/components/resume-print/resume-print.component';
import { SuspendPdfPrintComponent } from './dialogs/suspend-pdf-print/suspend-pdf-print.component';
import { CustomPaginatorComponent } from '../layout/buttoned-pagination/buttoned-pagination.component';




@NgModule({
  declarations: [
    InitialsUpperPipe,
    FormApiValidationErrorsComponent,
    CancelConfirmDialogComponent,
    GridFiltersComponent,
    SendOrdersComponent,
    QRDialogComponent,
    FiltersChimpComponent,
    GridMenuComponent,
    FilterChimpTextComponent,
    MessageDialogComponent,
    SuspendPrintComponent,
    ResumePrintComponent,
    SuspendPdfPrintComponent,
    CustomPaginatorComponent
  ],
  imports: [
    ApiModule,
    CommonModule,
    MaterialModule,
    LeafletModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [
    LeafletModule,
    ApiModule,
    FormApiValidationErrorsComponent,
    MaterialModule,
    ReactiveFormsModule,
    InitialsUpperPipe,
    CancelConfirmDialogComponent,
    MessageDialogComponent,
    GridFiltersComponent,
    TranslateModule,
    FiltersChimpComponent,
    GridMenuComponent,
    CustomPaginatorComponent
  ],
})
export class SharedModule {}

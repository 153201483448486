import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ReuseCenterDocs } from 'src/app/data/model/reuse-center-docs';
import { SuspendPdfPrintApiService } from '../../api/services/suspendpdfprint-api.service';

@Component({
  selector: 'app-suspend-pdf-print',
  templateUrl: './suspend-pdf-print.component.html',
  styleUrls: ['./suspend-pdf-print.component.scss']
})
export class SuspendPdfPrintComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: ReuseCenterDocs, private router: Router,
    private suspendresume: SuspendPdfPrintApiService) { }

    suspend():void{
      this.suspendresume.suspend_resume(true).subscribe();
      let currentUrl = this.router.url;
      this.router.navigateByUrl('/currentUrl',{skipLocationChange:true}).then(()=>{
        this.router.navigate([currentUrl]).then(()=>{
        });
      });
    }

}
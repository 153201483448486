<div>
    <h2 mat-dialog-title></h2>
    <mat-dialog-content >
      <p >Sei sicuro di voler sospendere la stampa ?</p>
    </mat-dialog-content>
    <mat-dialog-actions align="bottom">
      <button mat-button mat-dialog-close>Annulla</button>
      <button
        mat-button
        class=""
        (click)="suspend()"
        [mat-dialog-close]="true"
        cdkFocusInitial
        color="warning"
      >
        Invia
      </button>
    </mat-dialog-actions>
  </div>
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Models } from 'src/app/data/model/permission';
import { FilterField } from '../../dialogs/grid-filters/grid-filters.component';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry, MatIconModule} from '@angular/material/icon';
import { getTsBuildInfoEmitOutputFilePath } from 'typescript';

const SUSPEND_PRINT_ICON =
  `
  <?xml version="1.0" encoding="utf-8"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
<svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.14912 5.06333C6.05235 5.35805 6 5.67291 6 6V7H5C3.34315 7 2 8.34315 2 10V17C2 17.5523 2.44772 18 3 18H6C6 19.6569 7.34315 21 9 21H15C16.6569 21 18 19.6569 18 18H19.0858L14.0858 13H7C6.44772 13 6 13.4477 6 14V16H4V10C4 9.44772 4.44772 9 5 9H7H10.0858L8.08579 7H8V6.91421L6.14912 5.06333ZM10.9142 7H16V6C16 5.44772 15.5523 5 15 5H9C8.97224 5 8.94475 5.00113 8.91756 5.00335L7.38535 3.47114C7.85149 3.17289 8.40555 3 9 3H15C16.6569 3 18 4.34315 18 6V7H19C20.6569 7 22 8.34315 22 10V17C22 17.298 21.8697 17.5655 21.6629 17.7487L19.9142 16H20V10C20 9.44772 19.5523 9 19 9H17H12.9142L10.9142 7ZM15.9102 11.996L15.004 11.0898C15.0013 11.0602 15 11.0303 15 11C15 10.4477 15.4477 10 16 10H18C18.5523 10 19 10.4477 19 11C19 11.5523 18.5523 12 18 12H16C15.9697 12 15.9398 11.9987 15.9102 11.996ZM8 18V15H16V18C16 18.5523 15.5523 19 15 19H9C8.44772 19 8 18.5523 8 18Z" fill="#000000"/>
<path d="M3 3L21 21" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;
@Component({
  selector: 'app-grid-menu',
  templateUrl: './grid-menu.component.html',
  styleUrls: ['./grid-menu.component.scss'],
})
export class GridMenuComponent {


  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIconLiteral('suspend-print', sanitizer.bypassSecurityTrustHtml(SUSPEND_PRINT_ICON));
  }
  @Input() addText: string;
  @Input() addFilterText: string;
  @Input() gridFilters: FilterField[];
  @Input() clientSideGridFilters: FilterField[];
  @Input() filters: object;
  @Input() canAdd: boolean;
  @Input() canView: boolean;
  @Input() models: Models.VEHICLEQRCODE;
  @Input() delivery_codes: Models.DELCODE;
  @Input() Refresh:boolean=false;
  @Input() reusecenterdocuments: Models.DELDOCUMENTS;
  @Input() suspend_print:boolean=false;
  @Input() client_side_filters: object;
  

  @Output() openFilters = new EventEmitter();
  @Output() removeFilter = new EventEmitter<string>();
  @Output() addItem = new EventEmitter();
  @Output() Tracking = new EventEmitter();
  @Output() sendOrders = new EventEmitter();
  @Output() refreshPage = new EventEmitter();
  @Output() downloadQRcodes = new EventEmitter();
  @Output() openSuspendPrint = new EventEmitter();
  @Output() openResumePrint = new EventEmitter();
  @Output() openSuspendPdfPrint = new EventEmitter();
  @Output() openClientSideFilters = new EventEmitter();
  @Output() removeClientSideFilter = new EventEmitter<string>();

}

import { Component, ElementRef, EventEmitter, HostListener, Inject, LOCALE_ID, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { filter, map, Observable, startWith, switchMap, tap } from 'rxjs';
import { BaseComponent } from '../../base/base-component.directive';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {COMMA, ENTER} from '@angular/cdk/keycodes';


export const MY_DATE_FORMATS = {

  parse: {

    dateInput: 'DD/MM/YYYY',

  },

  display: {

    dateInput: 'DD/MM/YYYY',

    monthYearLabel: 'MMMM YYYY',

    dateA11yLabel: 'LL',

    monthYearA11yLabel: 'MMMM YYYY'

  },

};
@Component({
  selector: 'app-grid-filters',
  templateUrl: './grid-filters.component.html',
  styleUrls: ['./grid-filters.component.scss'],
  providers:[
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }]
})
export class GridFiltersComponent extends BaseComponent implements OnInit {
  filterFormGroup: FormGroup = this.formBuilder.group({
    field: [null, [Validators.required]],
    value: [null, [Validators.required]],
    type:[null]
  });

  range: FormGroup= this.formBuilder.group({
    field: [null, [Validators.required]],
    start: [null, [Validators.required]],
    end: [null, [Validators.required]],
  });

  filteredOptions: Observable<FilterFieldOption[]>;


  options: FilterFieldOption[];
  value: any;
  values:any[]=[]
  start: string;
  end: string;
  separatorKeysCodes: number[] = [ENTER, COMMA]
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  selectedValue= 0;
  type?:string[]

  rawData: Array<any> = [];
  selectData: Array<any> = []

  @ViewChild('valueInput') valueInput: ElementRef;
  get selectedField(): FilterField {
    return this.filterFormGroup.value?.field;
  }

  get FieldType(): typeof FieldType {
    return FieldType;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: GridFilterParam,
    private dialogRef: MatDialogRef<GridFiltersComponent>,
    private formBuilder: FormBuilder
  ) {
    super();
  }

  get_subcategory(value:number){
    if(this.options[0].json && this.filterFormGroup.value && typeof(value)==='number'){
      return this.options?.filter((option) =>
        option?.id === value
      )[0].json;
    }
    return []
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.values.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
  }

  remove(option: FilterFieldOption): void {
    const index = this.values.indexOf(option);
    if (index >= 0) {
      this.values.splice(index, 1);
    }
  }
  
  selected(event: MatAutocompleteSelectedEvent): void {
    this.values.push(event.option.viewValue);
    this.valueInput.nativeElement.value = '';
  }
  
  
  ngOnInit(): void {
    this.filteredOptions = this.filterFormGroup.get('value').valueChanges.pipe(
      startWith(''),
      map((val: string | FilterFieldOption) => this._filterOptions(val))
    );
    
    this.range
      .get('field')
      .valueChanges.pipe(filter((val) => val.options))
      .pipe(switchMap((val) => val.options as Observable<FilterFieldOption[]>))
      .pipe(this.autoDispose())
      .subscribe((res) => (this.options = res));

    this.filterFormGroup
      .get('field')
      .valueChanges.pipe(filter((val) => val.options))
      .pipe(switchMap((val) => val.options as Observable<FilterFieldOption[]>))
      .pipe(this.autoDispose())
      .subscribe((res) => (this.options = res));

  }


  addFilter() {

    this.range.markAllAsTouched();
    this.filterFormGroup.markAllAsTouched();
    if (this.values.length !==0){
      this.filterFormGroup.value.value=this.values;
    }
    if(this.filterFormGroup.value.value !== null ) {
      if (this.filterFormGroup.valid) {
        const { field, value, type } = this.filterFormGroup.value;
        if (type != null){
          this.dialogRef.close({ [field.name]: value, type});
        } else {
          this.dialogRef.close({ [field.name]: value});
        }
      }
    }
    if(this.range.value.start !== null && this.range.value.end !== null && this.filterFormGroup.value.field.type === 5){
      const start=moment(this.range.value.start._d).format('yyyy-MM-DD');
      const end=moment(this.range.value.end._d).format('yyyy-MM-DD');
      this.range.value.field=this.filterFormGroup.value.field;
      const { field, value=`${start},${end}` } = this.range.value;
      this.dialogRef.close({ [field.name]: value });
    }
    if(this.range.value.start !== null && this.range.value.end !== null && this.filterFormGroup.value.field.type === 9){
      const start=this.range.value.start._d.valueOf();
      const end=this.range.value.end._d.valueOf();
      this.range.value.field=this.filterFormGroup.value.field;
      const { field, value=`${start},${end}` } = this.range.value;
      this.dialogRef.close({ [field.name]: value });
    }
  }


  private _filterOptions(
    value: string | FilterFieldOption
  ): FilterFieldOption[] {
    if (typeof value === 'string') {
      const search = value.toUpperCase();
      return this.options?.filter((option) =>
        option?.description.toUpperCase().includes(search)
      );
    }
    return [];
  }

  check_if_json(){
    var res:boolean;
    if(this.options !== undefined){
      this.options.some(array=>{
        res=array.hasOwnProperty("json")
      })
    } else {
      res = false;
    }
    return res
  }

  displayFn() {
    return (groupId: string | number) =>
      this.options?.find((item) => item.description === groupId)?.description || this.options?.find((item) => item.id === groupId)?.description; 
    /*return (groupId: number) =>
      this.options?.find((item) => item.id === groupId)?.description;*/
  }
}

export type GridFilterParam = {
  fields: FilterField[];
};

export type FilterField = {
  name: string;
  text: string;
  type: FieldType;
  options?: Observable<FilterFieldOption[]>;
};

export enum FieldType {
  TEXT,//this.filterFormGroup.value.field.type===0
  AUTOCOMPLETE,//this.filterFormGroup.value.field.type===1
  DATETIME,//this.filterFormGroup.value.field.type===2
  UPPERCASE,//this.filterFormGroup.value.field.type===3
  UPPERCASE_ARRAY,//this.filterFormGroup.value.field.type===4
  DATERANGE,//this.filterFormGroup.value.field.type===5
  STATUS,//this.filterFormGroup.value.field.type===6
  LOwERCASE_ARRAY,//this.filterFormGroup.value.field.type===7
  BOOLEAN,//this.filterFormGroup.value.field.type===8
  GRAFANA_DATERANGE,//this.filterFormGroup.value.field.type===9
  GRAFANA_AUTOCOMPLETE,
  CONTRACT_TYPE,
  VEHICLE_STATUS,
  DOCTYPE,
  CLIENT_SIDE_BOOLEAN,
  AUTOCOMPLETE_DATE,
  AUTOCOMPLETE_UPPER,
  WASTE_TYPE,
  LOCATION,
  WHITELIST_TYPE,
  WLTAG_LIST_MODE
}

export type FilterFieldOption = { id: number; description: string, json?:NestedJson[]  };
export type NestedJson ={ cod:string; tipologia: string;}

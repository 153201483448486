<button mat-button [matMenuTriggerFor]="menuPanel">
  {{ item?.label }}
</button>
<mat-menu #menuPanel="matMenu">
  <div *ngFor="let child of item?.items">
    <button
      *ngIf="!child.sub_menu"
      mat-menu-item
      [routerLink]="child.link"
      routerLinkActive="active"
    >
      {{ child.label }}
    </button>
    <button
      *ngIf="child.sub_menu"
      mat-menu-item
      [matMenuTriggerFor]="subMenuPanel"
    >
      {{ child.label }}
    </button>
    <mat-menu #subMenuPanel="matMenu">
        <button
          *ngFor="let sub of child.sub_menu?.items"
          mat-menu-item
          [routerLink]="sub.link"
          routerLinkActive="active"
        >
          {{ sub.label }}
        </button>
    </mat-menu>
  </div>
</mat-menu>

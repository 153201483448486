<div class="footer" fxLayout="row" fxLayoutAlign="center">
  <div class="container wow fadeIn" style="visibility: visible" fxLayout="row" >
    <p class="title-size bold">MQUADRO S.R.L.</p>
    <div class="row w-100 flex">
      <div class="col col-sx-2">
        <ul class="list-bare list-bare-smaller" >
          <li><span class="bold">Sede Operativa</span></li>
          <li>Via del Follatoio 12</li>
          <li>34148 Trieste</li>
          <li>Italia</li>
        </ul>
      </div>
      <div class="col col-sx-2" fxLayout="row" >
        <ul class="list-bare list-bare-smaller">
          <li><span class="bold">Sede Legale</span></li>
          <li>Via Giusti 2</li>
          <li>33100 Udine</li>
          <li>Italia</li>
        </ul>
      </div>
      <div class="col col-sx-2" fxLayout="row" >
        <ul class="list-bare list-bare-smaller">
          <li><span class="bold">Contatti</span></li>
          <li>Tel: +39 040 8992265</li>
          <li>Fax: +39 040 8992264</li>
          <li>
            E-Mail: <a href="mailto:info@mquadro.net">info@mquadro.net</a>
          </li>
        </ul>
      </div>
      <div class="col col-sx-2">
        <ul class="list-bare list-bare-smaller">
          <li><b>P.IVA/CF: </b> 02549160303</li>
          <li><b>C.S.: </b> € 20.000,00 i.v.</li>
        </ul>
      </div>
    </div>
  </div>
</div>

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { RootStoreModule } from './store/store.module';
import { environment } from 'src/environments/environment';
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { UserMenuComponent } from './layout/user-menu/user-menu.component';
import { NavigationMenuComponent } from './layout/navigation-menu/navigation-menu.component';
import { NavigationMenuItemComponent } from './layout/navigation-menu/components/navigation-menu-item/navigation-menu-item.component';
import { NavigationListItemComponent } from './layout/navigation-menu/components/navigation-list-item/navigation-list-item.component';
import { I18nModule } from './i18n/i18n.module';
import { SharedModule } from './shared/shared.module';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';



@NgModule({
  declarations: [
    AppComponent,
    ContentLayoutComponent,
    HeaderComponent,
    FooterComponent,
    UserMenuComponent,
    NavigationMenuComponent,
    NavigationMenuItemComponent,
    NavigationListItemComponent,
  ],
  imports: [
    LeafletModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule.forRoot({ environment }),
    SharedModule,
    RootStoreModule,
    I18nModule
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
